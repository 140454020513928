
.invalid-feedback {
    text-align: left;
}

.form-control.is-invalid {
    border-color: #dc3545 !important;
}

#containerbar {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.rightbar {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.flex-full {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.contentbar {
    padding-bottom: 0 !important;
}


.footer-2 {
    text-align: center;
    padding: 10px;
  }

.q-box {
    text-decoration: none !important;
    color: #ccc;
    border: solid 2px #CCC;
    height: 40px;
    width: 40px;
    line-height: 37px;
    vertical-align: middle;
    text-align: center;
    border-radius: 50%;
    font-weight: bold;
  }
  
  .q-box.active {
    background-color: #506fe4;
    color: white  !important;
    border: solid 2px #506fe4;
  }
  
  .q-box-submit {
    width: auto;
    padding-left: 10px;
    padding-right: 10px;
  }

  .is-invalid label {
    color: orangered !important;
  }

  .is-valid label {
    color: yellowgreen !important;
  }
  
  ul.results {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .q-box.wrong {
    color: red !important;
    border: solid 2px red;
  }

  .q-box.done {
    color: green !important;
    border: solid 2px green;
  }

  
  .q-box.done.active {
    color: #FFF !important;
    background: green;
  }

  .q-box.wrong.active {
    color: #FFF !important;
    background: red;
  }
  
  .q-box.partial {
    color: orange !important;
    border: solid 2px orange;
  }
  
  .q-box.missing {
    color: red !important;
    border: solid 2px red;
  }
  
  .q-box-btn:not(.disabled):not(.looks-disabled) {
    background: #506fe4;
    border-color: #506fe4;
    color: #fff !important;
  }
  
  .q-box-sq {
    border-radius: 3px;
  }
  
  .disabled {
    pointer-events: none;
    opacity: 0.4;
  }
  
  
.filing-root {
    display: inline;
  }
  
  .filing {
    display: inline-block;
    vertical-align: top;
    text-align: center;
    padding: 0 2px
  }
  
  .filing .wrong {
    color: red;
    text-decoration: line-through;
    font-weight: bold;
    margin-right: 3px;
  }
  
  .filing .incorrect {
    color: red;
  }
  
  .filing .correct {
    color: black;
  }
  
  .filing .correct {
    color: green;
  }
  
  .filing .answer {
    display: block;
  }
  
  .filing .points {
    font-size: .75rem;
  }

  .intro-box {
    position: relative;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .intro-box > div {
    position: relative;
    width: 850px;
    height: 600px;
    padding: 30px 0 30px 30px;
    background-color: #FFF;
    box-shadow: -1px 0px 69px -28px rgba(204,204,204,1);
  }

  .intro-box > video {
    position: absolute;
    z-index: -1;
    background: #FFF;
  }

  .intro-box p {
    line-height: 1.6;
    font-size: 18px;
    font-weight: bold;
  }

  .intro-box > div img {
    height: 80px;
  }

  .intro-box > div img.small {
    margin-top: 20px;
    height: 18px;
    vertical-align: bottom;
  }

  .intro-box > div img.small:last-child {
    margin-left: 10px;
    height: 28px;
  }

  .intro-box .padding-div {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .intro-box .btn, .intro-box .btn i {
    vertical-align: middle;
  }

  .intro-box a.small-text {
    display: block;
    margin-top: 25px;
    font-weight: normal;
    font-size: 14px;
  }

  .intro-box .padding-div > *{
    margin: 20px 0;
  }

  .star-active {
    color: #f9d71c;
  }
  
  .video-padding {
    padding: 0 30% 0 0;
  }
  
  .progress-bar-info {
    display: flex;
    flex-direction: row;
  }

  .progress-bar-info > div {
    flex-grow: 1;
    text-align: center;
    padding: 0 15px;
  }

  .video-full-intro video {
    width: 100%;
  }

  .match-block {
    padding: 10px 0;

    display: flex;
    align-items: center;
  }

  .match-block > div {
    padding: 5px 10px;
    flex-grow: 1;
    
    border: solid 1px #506fe4;
    height: 60px;
  }

  .sixteen-nine {
    padding-top: 56.25%;
  }

  .sixteen-nine > div {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display:flex;
  }

  .avatar-chooser {
    display: flex;
    flex-direction: column;

  }

  .avatar {
    border-radius: 50%;

  }

  .avatar-chooser > img {
    width: 100px;
    height: 100px;
    margin: 10px;
    display: block;
    margin: 0 auto;
  }

  .avatar-chooser > div {
    margin-top: 25px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .avatar-chooser > div > a {
    flex: 0 33.3%;
    display: block;
    text-align: center;
    cursor: pointer;
    position: relative;
  }

  
  .avatar-chooser > div > a > img {
    width: 35px;
    position: relative;
    height: 35px;
    margin: 10px;
    border: solid 2px #506fe4;
  }

  .avatar-chooser > div > a.selected > img {
    border-color: #dc3545;
  }

  .avatar-chooser > div > a.selected::before {
    z-index:1;
    position: absolute;
    bottom: 13px;
    right: 13px;
    content: '';
    width: 8px;
    height: 8px;
    background-color: #dc3545;
    border-radius: 50%;
  }

  .form-group label {
    font-weight: 600;
  }

  .Toastify__toast-container--top-center {
    width: 450px !important;
  }

  .text-toast {
    font-size: 0.95em;
    color: #000 !important;
  }

  .icon-big {
    font-size: 1em !important;
    margin-right: 5px;
  }

  .text-underline {
    text-decoration: underline;
  }

  .bg-danger .text-underline {
    font-size: .70em;
    color: white;
  }

  .bg-danger .text-underline:hover {
    color: white;
  }

  .bg-danger p.font-16 {
    color: white;
  }

  .is-completed > a > span {
    color: #43d187;
  }

  .is-completed > a > span:before {
    font-family: "feather" !important;
    margin-right: 5px;
    content: '\e8d6';
    color: #43d187;
  }

  .vertical-menu .vertical-submenu > li > a.is-completed {
    color: #43d187;
  }

  .vertical-menu .vertical-submenu > li > a.is-completed:before {
    content: '\e8d6';
    color: #43d187;
  }

  .vertical-menu .vertical-submenu > li > a.is-disabled {
    color: #dc3545;
  }

  .vertical-menu .vertical-submenu > li > a.is-disabled:before {
    content: '\e81c';
    color: #dc3545;
  }

  .active-drop .match-block > div {
    border-color: #dc3545;
  }

  ul.lang-switch {
    display: block;
    list-style: none;
    padding: 0;
    margin-top: 25px;
    text-align: center;
  }

  ul.lang-switch li {
    margin: 0 5px;
    display: inline-block;
    text-align: center;
    width: 75px;
  }

  ul.lang-switch li img {
    display: block;
    margin: 0 auto;
    width: 30px;
    height: 30px;
  }

  ul.lang-switch li span {
    display: block;
    margin-top: 5px;
  }

  ul.lang-switch li a.active {
    pointer-events: none;
  }

  ul.lang-switch li a.active span {
    color: black;
    font-weight: bold;
  }

  .question-body {
    color: #555;
    font-weight: 500;
  }